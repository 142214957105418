<template lang="pug">
  .d-flex.px-4(style="width:100%")
    transition(name="fade" mode="out-in")
      AgencyContactScreen(v-if="isAgency" @filled="onFilled")
      MidLevelContactScreen(v-else @filled="onFilled")
</template>

<script>
  import mobileOnboarding from '@/mixins/mobileOnboarding';
  import saveStep from '@/mixins/saveOnboardingProgress';
  import { mapActions } from 'vuex';
  import MidLevelContactScreen from '../ContactScreens/MidLevel.vue';
  import AgencyContactScreen from '../ContactScreens/Agency.vue';

  export default {
    name: 'MobileOnboardingContact',
    components: { MidLevelContactScreen, AgencyContactScreen },
    mixins: [mobileOnboarding, saveStep],
    computed: {
      isAgency() {
        return ['shopifyAgency', 'agency'].includes(this.whichContactFlow());
      },
    },
    methods: {
      ...mapActions(['finishOnboarding']),
      async onFilled() {
        if (this.isAgency) {
          this.$router.push({ name: 'agency_benefits_steps', params: { step: 'we-can' } });
        } else {
          this.next();
        }
        await this.finishOnboarding();
      },
      validate() {
        return true;
      },
    },
  };
</script>
