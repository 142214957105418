<template lang="pug">
.d-flex.flex-column
  .onboarding-info.mt-3
    .onboarding-info-inner.text-center {{ $t('mobileOnboarding.subtitles.contactMid') }}
  transition-group(name="fade" mode="out-in")
    .brand-mobile-onboarding-title-sm.mb-2(key="title") {{ $t('mobileOnboarding.titles.contactMid2') }}
    .brand-mobile-onboarding-subtitle-sm.mb-4(key="subtitle") {{ $t('mobileOnboarding.titles.contactDesc2') }}
    .brand-mobile-onboarding-input.mb-2.pt-3(key="input")
      .brand-mobile-onboarding-input-label.mb-2 {{ $t('mobileOnboarding.inputs.labels.phone') }}
      input#phoneNumber.form-control.form-control-lg(ref="phoneInput" v-model="phone" type="text")
      .brand-input-error(v-if="$v.phone.$error && $v.phone.required && !$v.phone.isValid") {{ $t('notValidFormat') }}
      .brand-input-error(v-if="$v.phone.$error && !$v.phone.required") {{ $t('requiredField') }}
    button.btn.brand-btn-primary.brand-mobile-onboarding-btn-full.w-100(key="button" @click="done") {{ $t('next') }}
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import SEND_ADDITIONAL_ONBOARDING_INFO from '@/graphql/SendAdditionalOnboardingInfo.gql';
  import * as intlTelInput from 'intl-tel-input';
  import 'intl-tel-input/build/js/utils';
  import GEOIP_LOOKUP from '@/graphql/GeoIpLookup.gql';
  import * as Cookie from 'js-cookie';

  export default {
    name: 'MobileOnboardingMidLevelContact',
    data: () => ({ phone: null }),
    validations: {
      phone: {
        required,
        isValid() {
          return this.step === 1 ? true : this.iti.isValidNumber();
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initPhoneNumber();
      });
    },
    methods: {
      async done() {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.$v.$reset();

        await this.$apollo.mutate({
          mutation: SEND_ADDITIONAL_ONBOARDING_INFO,
          variables: {
            phoneNumber: this.iti ? this.iti.getNumber() : null,
            agencyIndustry: this.$route.query.industry,
            clientsNumber: null,
          },
        });
        this.$emit('filled');
      },
      initPhoneNumber() {
        if (!this.$refs.phoneInput || this.iti) {
          return;
        }

        this.iti = intlTelInput(this.$refs.phoneInput, {
          separateDialCode: true,
          initialCountry: 'auto',
          geoIpLookup: async (success, failure) => {
            try {
              let country = Cookie.get('x-country-code');

              if (!country) {
                const {
                  data: { geoIpLookup },
                } = await this.$apollo.query({
                  query: GEOIP_LOOKUP,
                });

                country = geoIpLookup;
                if (geoIpLookup) {
                  Cookie.set('x-country-code', geoIpLookup);
                }
              }

              success(country || 'US');
            } catch (e) {
              failure(e);
            }
          },
        });
      },
    },
  };
</script>

<style lang="sass">
  .onboarding-info
    padding: 1.0625rem 1rem 1.25rem
    background: #F6F7F9
    color: #666666
    margin-bottom: 2.875rem
    &-inner
      font-size: 0.9375rem
      line-height: 1.55
</style>
