<template lang="pug">
.d-flex.flex-column.pt-3
  //- PHONE
  .brand-mobile-onboarding-input.mb-3(v-if="!hasActiveShopify")
    label.brand-mobile-onboarding-input-label(for="phoneNumber") {{ $t('mobileOnboarding.inputs.labels.phoneAgency') }}
    input#phoneNumber.form-control.form-control-lg.brand-input(
      ref="phoneInput"
      v-model="phone"
      type="text"
    )
    .brand-input-error(v-if="$v.phone.$error && $v.phone.required && !$v.phone.isValid") {{ $t('notValidFormat') }}
    .brand-input-error(v-if="$v.phone.$error && !$v.phone.required") {{ $t('requiredField') }}
  //- MARKETFIT
  .brand-mobile-onboarding-input.mb-3
    label.brand-mobile-onboarding-input-label(for="type") {{ $t('mobileOnboarding.inputs.labels.type') }}
    select#type.form-control.form-control-lg(
      :class="{ 'is-invalid': $v.type.$error, 'form-control-empty': type === null }"
      v-model="type"
    )
      option(:value="null" selected disabled) {{ $t('mobileOnboarding.inputs.placeholders.type') }}
      option(v-for="option in industries" :value="option") {{ option }}
    .brand-input-error(v-if="$v.type.$error && $v.type.required && !$v.type.isValid") {{ $t('notValidFormat') }}
    .brand-input-error(v-if="$v.type.$error && !$v.type.required") {{ $t('requiredField') }}
  //- CLIENTS
  .brand-mobile-onboarding-input
    label.brand-mobile-onboarding-input-label(for="clients") {{ $t('mobileOnboarding.inputs.labels.clients') }}
    input#clients.form-control.form-control-lg.brand-input(
      type="number"
      v-model="clients"
      :placeholder="$t('mobileOnboarding.inputs.placeholders.clients')"
    )
    .brand-input-error(v-if="$v.clients.$error && $v.clients.required && !$v.clients.isValid") {{ $t('notValidFormat') }}
    .brand-input-error(v-if="$v.clients.$error && !$v.clients.required") {{ $t('requiredField') }}
  button.btn.brand-btn-primary.brand-mobile-onboarding-btn-full(key="button" @click="save") {{ $t('next') }}
</template>

<script>
  import { agencyIndustries } from '@/config/agency';
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import SEND_ADDITIONAL_ONBOARDING_INFO from '@/graphql/SendAdditionalOnboardingInfo.gql';
  import * as intlTelInput from 'intl-tel-input';
  import 'intl-tel-input/build/js/utils';
  import GEOIP_LOOKUP from '@/graphql/GeoIpLookup.gql';
  import * as Cookie from 'js-cookie';
  import { mapGetters } from 'vuex';

  export default {
    name: 'MobileOnboardingAgencyContact',
    data: () => ({
      industries: agencyIndustries,
      phone: null,
      type: null,
      clients: null,
    }),
    computed: {
      ...mapGetters(['hasActiveShopify']),
    },
    validations: {
      phone: {
        required: requiredIf(function () {
          return !this.hasActiveShopify;
        }),
        isValid() {
          return this.iti.isValidNumber();
        },
      },
      type: { required },
      clients: { required },
    },
    mounted() {
      this.initPhoneNumber();
    },
    methods: {
      async save() {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.$v.$reset();
        let clientsNumber = null;

        try {
          clientsNumber = parseInt(this.clients, 10);
        } catch (e) {
          console.error(e);
        }

        await this.$apollo.mutate({
          mutation: SEND_ADDITIONAL_ONBOARDING_INFO,
          variables: {
            phoneNumber: this.iti ? this.iti.getNumber() : null,
            agencyIndustry: this.type,
            clientsNumber,
          },
        });
        this.$emit('filled');
      },
      initPhoneNumber() {
        if (!this.$refs.phoneInput || this.iti) {
          return;
        }

        this.iti = intlTelInput(this.$refs.phoneInput, {
          separateDialCode: true,
          initialCountry: 'auto',
          geoIpLookup: async (success, failure) => {
            try {
              let country = Cookie.get('x-country-code');

              if (!country) {
                const {
                  data: { geoIpLookup },
                } = await this.$apollo.query({
                  query: GEOIP_LOOKUP,
                });

                country = geoIpLookup;
                if (geoIpLookup) {
                  Cookie.set('x-country-code', geoIpLookup);
                }
              }

              success(country || 'US');
            } catch (e) {
              failure(e);
            }
          },
        });
      },
    },
  };
</script>
